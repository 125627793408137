.cookie-consent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1000;
    font-size: 12px;
    color: #fff;
    background: rgba(0,0,0,.7);
    padding: 1.2em;
    box-sizing: border-box;
}

.cookie_height {
    height:129px
  }

.cookie-agree {
    color: #fff;
    background: dodgerblue;
    padding: .5em 1.5em;
}
.cookie-agree:hover {
    cursor: pointer;
}